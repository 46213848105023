import React, { useState } from 'react';
import Select from 'react-select';
import html2canvas from 'html2canvas';
import { Snackbar, Alert, Button, Typography, Box, Container, CircularProgress, Avatar } from '@mui/material';

// Import the static image
import STATIC_IMAGE from '../src/Components/Image/img.png';

const countries = [
  { label: 'Afghanistan', value: 'AF', flag: 'https://kapowaz.github.io/square-flags/flags/af.svg' },
  { label: 'Albania', value: 'AL', flag: 'https://kapowaz.github.io/square-flags/flags/al.svg' },
  { label: 'Algeria', value: 'DZ', flag: 'https://kapowaz.github.io/square-flags/flags/dz.svg' },
  { label: 'Andorra', value: 'AD', flag: 'https://kapowaz.github.io/square-flags/flags/ad.svg' },
  { label: 'Angola', value: 'AO', flag: 'https://kapowaz.github.io/square-flags/flags/ao.svg' },
  { label: 'Antigua and Barbuda', value: 'AG', flag: 'https://kapowaz.github.io/square-flags/flags/ag.svg' },
  { label: 'Argentina', value: 'AR', flag: 'https://kapowaz.github.io/square-flags/flags/ar.svg' },
  { label: 'Armenia', value: 'AM', flag: 'https://kapowaz.github.io/square-flags/flags/am.svg' },
  { label: 'Australia', value: 'AU', flag: 'https://kapowaz.github.io/square-flags/flags/au.svg' },
  { label: 'Austria', value: 'AT', flag: 'https://kapowaz.github.io/square-flags/flags/at.svg' },
  { label: 'Azerbaijan', value: 'AZ', flag: 'https://kapowaz.github.io/square-flags/flags/az.svg' },
  { label: 'Bahamas', value: 'BS', flag: 'https://kapowaz.github.io/square-flags/flags/bs.svg' },
  { label: 'Bahrain', value: 'BH', flag: 'https://kapowaz.github.io/square-flags/flags/bh.svg' },
  { label: 'Bangladesh', value: 'BD', flag: 'https://kapowaz.github.io/square-flags/flags/bd.svg' },
  { label: 'Barbados', value: 'BB', flag: 'https://kapowaz.github.io/square-flags/flags/bb.svg' },
  { label: 'Belarus', value: 'BY', flag: 'https://kapowaz.github.io/square-flags/flags/by.svg' },
  { label: 'Belgium', value: 'BE', flag: 'https://kapowaz.github.io/square-flags/flags/be.svg' },
  { label: 'Belize', value: 'BZ', flag: 'https://kapowaz.github.io/square-flags/flags/bz.svg' },
  { label: 'Benin', value: 'BJ', flag: 'https://kapowaz.github.io/square-flags/flags/bj.svg' },
  { label: 'Bhutan', value: 'BT', flag: 'https://kapowaz.github.io/square-flags/flags/bt.svg' },
  { label: 'Bolivia', value: 'BO', flag: 'https://kapowaz.github.io/square-flags/flags/bo.svg' },
  { label: 'Bosnia and Herzegovina', value: 'BA', flag: 'https://kapowaz.github.io/square-flags/flags/ba.svg' },
  { label: 'Botswana', value: 'BW', flag: 'https://kapowaz.github.io/square-flags/flags/bw.svg' },
  { label: 'Brazil', value: 'BR', flag: 'https://kapowaz.github.io/square-flags/flags/br.svg' },
  { label: 'Brunei', value: 'BN', flag: 'https://kapowaz.github.io/square-flags/flags/bn.svg' },
  { label: 'Bulgaria', value: 'BG', flag: 'https://kapowaz.github.io/square-flags/flags/bg.svg' },
  { label: 'Burkina Faso', value: 'BF', flag: 'https://kapowaz.github.io/square-flags/flags/bf.svg' },
  { label: 'Burundi', value: 'BI', flag: 'https://kapowaz.github.io/square-flags/flags/bi.svg' },
  { label: 'Cabo Verde', value: 'CV', flag: 'https://kapowaz.github.io/square-flags/flags/cv.svg' },
  { label: 'Cambodia', value: 'KH', flag: 'https://kapowaz.github.io/square-flags/flags/kh.svg' },
  { label: 'Cameroon', value: 'CM', flag: 'https://kapowaz.github.io/square-flags/flags/cm.svg' },
  { label: 'Canada', value: 'CA', flag: 'https://kapowaz.github.io/square-flags/flags/ca.svg' },
  { label: 'Central African Republic', value: 'CF', flag: 'https://kapowaz.github.io/square-flags/flags/cf.svg' },
  { label: 'Chad', value: 'TD', flag: 'https://kapowaz.github.io/square-flags/flags/td.svg' },
  { label: 'Chile', value: 'CL', flag: 'https://kapowaz.github.io/square-flags/flags/cl.svg' },
  { label: 'China', value: 'CN', flag: 'https://kapowaz.github.io/square-flags/flags/cn.svg' },
  { label: 'Colombia', value: 'CO', flag: 'https://kapowaz.github.io/square-flags/flags/co.svg' },
  { label: 'Comoros', value: 'KM', flag: 'https://kapowaz.github.io/square-flags/flags/km.svg' },
  { label: 'Congo (Congo-Brazzaville)', value: 'CG', flag: 'https://kapowaz.github.io/square-flags/flags/cg.svg' },
  { label: 'Congo (Democratic Republic)', value: 'CD', flag: 'https://kapowaz.github.io/square-flags/flags/cd.svg' },
  { label: 'Costa Rica', value: 'CR', flag: 'https://kapowaz.github.io/square-flags/flags/cr.svg' },
  { label: 'Croatia', value: 'HR', flag: 'https://kapowaz.github.io/square-flags/flags/hr.svg' },
  { label: 'Cuba', value: 'CU', flag: 'https://kapowaz.github.io/square-flags/flags/cu.svg' },
  { label: 'Cyprus', value: 'CY', flag: 'https://kapowaz.github.io/square-flags/flags/cy.svg' },
  { label: 'Czech Republic (Czechia)', value: 'CZ', flag: 'https://kapowaz.github.io/square-flags/flags/cz.svg' },
  { label: 'Denmark', value: 'DK', flag: 'https://kapowaz.github.io/square-flags/flags/dk.svg' },
  { label: 'Djibouti', value: 'DJ', flag: 'https://kapowaz.github.io/square-flags/flags/dj.svg' },
  { label: 'Dominica', value: 'DM', flag: 'https://kapowaz.github.io/square-flags/flags/dm.svg' },
  { label: 'Dominican Republic', value: 'DO', flag: 'https://kapowaz.github.io/square-flags/flags/do.svg' },
  { label: 'Ecuador', value: 'EC', flag: 'https://kapowaz.github.io/square-flags/flags/ec.svg' },
  { label: 'Egypt', value: 'EG', flag: 'https://kapowaz.github.io/square-flags/flags/eg.svg' },
  { label: 'El Salvador', value: 'SV', flag: 'https://kapowaz.github.io/square-flags/flags/sv.svg' },
  { label: 'Equatorial Guinea', value: 'GQ', flag: 'https://kapowaz.github.io/square-flags/flags/gq.svg' },
  { label: 'Eritrea', value: 'ER', flag: 'https://kapowaz.github.io/square-flags/flags/er.svg' },
  { label: 'Estonia', value: 'EE', flag: 'https://kapowaz.github.io/square-flags/flags/ee.svg' },
  { label: 'Eswatini', value: 'SZ', flag: 'https://kapowaz.github.io/square-flags/flags/sz.svg' },
  { label: 'Ethiopia', value: 'ET', flag: 'https://kapowaz.github.io/square-flags/flags/et.svg' },
  { label: 'Fiji', value: 'FJ', flag: 'https://kapowaz.github.io/square-flags/flags/fj.svg' },
  { label: 'Finland', value: 'FI', flag: 'https://kapowaz.github.io/square-flags/flags/fi.svg' },
  { label: 'France', value: 'FR', flag: 'https://kapowaz.github.io/square-flags/flags/fr.svg' },
  { label: 'Gabon', value: 'GA', flag: 'https://kapowaz.github.io/square-flags/flags/ga.svg' },
  { label: 'Gambia', value: 'GM', flag: 'https://kapowaz.github.io/square-flags/flags/gm.svg' },
  { label: 'Georgia', value: 'GE', flag: 'https://kapowaz.github.io/square-flags/flags/ge.svg' },
  { label: 'Germany', value: 'DE', flag: 'https://kapowaz.github.io/square-flags/flags/de.svg' },
  { label: 'Ghana', value: 'GH', flag: 'https://kapowaz.github.io/square-flags/flags/gh.svg' },
  { label: 'Greece', value: 'GR', flag: 'https://kapowaz.github.io/square-flags/flags/gr.svg' },
  { label: 'Grenada', value: 'GD', flag: 'https://kapowaz.github.io/square-flags/flags/gd.svg' },
  { label: 'Guatemala', value: 'GT', flag: 'https://kapowaz.github.io/square-flags/flags/gt.svg' },
  { label: 'Guinea', value: 'GN', flag: 'https://kapowaz.github.io/square-flags/flags/gn.svg' },
  { label: 'Guinea-Bissau', value: 'GW', flag: 'https://kapowaz.github.io/square-flags/flags/gw.svg' },
  { label: 'Guyana', value: 'GY', flag: 'https://kapowaz.github.io/square-flags/flags/gy.svg' },
  { label: 'Haiti', value: 'HT', flag: 'https://kapowaz.github.io/square-flags/flags/ht.svg' },
  { label: 'Honduras', value: 'HN', flag: 'https://kapowaz.github.io/square-flags/flags/hn.svg' },
  { label: 'Hungary', value: 'HU', flag: 'https://kapowaz.github.io/square-flags/flags/hu.svg' },
  { label: 'Iceland', value: 'IS', flag: 'https://kapowaz.github.io/square-flags/flags/is.svg' },
  { label: 'India', value: 'IN', flag: 'https://kapowaz.github.io/square-flags/flags/in.svg' },
  { label: 'Indonesia', value: 'ID', flag: 'https://kapowaz.github.io/square-flags/flags/id.svg' },
  { label: 'Iran', value: 'IR', flag: 'https://kapowaz.github.io/square-flags/flags/ir.svg' },
  { label: 'Iraq', value: 'IQ', flag: 'https://kapowaz.github.io/square-flags/flags/iq.svg' },
  { label: 'Ireland', value: 'IE', flag: 'https://kapowaz.github.io/square-flags/flags/ie.svg' },
  { label: 'Israel', value: 'IL', flag: 'https://kapowaz.github.io/square-flags/flags/il.svg' },
  { label: 'Italy', value: 'IT', flag: 'https://kapowaz.github.io/square-flags/flags/it.svg' },
  { label: 'Jamaica', value: 'JM', flag: 'https://kapowaz.github.io/square-flags/flags/jm.svg' },
  { label: 'Japan', value: 'JP', flag: 'https://kapowaz.github.io/square-flags/flags/jp.svg' },
  { label: 'Jordan', value: 'JO', flag: 'https://kapowaz.github.io/square-flags/flags/jo.svg' },
  { label: 'Kazakhstan', value: 'KZ', flag: 'https://kapowaz.github.io/square-flags/flags/kz.svg' },
  { label: 'Kenya', value: 'KE', flag: 'https://kapowaz.github.io/square-flags/flags/ke.svg' },
  { label: 'Kiribati', value: 'KI', flag: 'https://kapowaz.github.io/square-flags/flags/ki.svg' },
  { label: 'Korea, North', value: 'KP', flag: 'https://kapowaz.github.io/square-flags/flags/kp.svg' },
  { label: 'Korea, South', value: 'KR', flag: 'https://kapowaz.github.io/square-flags/flags/kr.svg' },
  { label: 'Kuwait', value: 'KW', flag: 'https://kapowaz.github.io/square-flags/flags/kw.svg' },
  { label: 'Kyrgyzstan', value: 'KG', flag: 'https://kapowaz.github.io/square-flags/flags/kg.svg' },
  { label: 'Laos', value: 'LA', flag: 'https://kapowaz.github.io/square-flags/flags/la.svg' },
  { label: 'Latvia', value: 'LV', flag: 'https://kapowaz.github.io/square-flags/flags/lv.svg' },
  { label: 'Lebanon', value: 'LB', flag: 'https://kapowaz.github.io/square-flags/flags/lb.svg' },
  { label: 'Lesotho', value: 'LS', flag: 'https://kapowaz.github.io/square-flags/flags/ls.svg' },
  { label: 'Liberia', value: 'LR', flag: 'https://kapowaz.github.io/square-flags/flags/lr.svg' },
  { label: 'Libya', value: 'LY', flag: 'https://kapowaz.github.io/square-flags/flags/ly.svg' },
  { label: 'Liechtenstein', value: 'LI', flag: 'https://kapowaz.github.io/square-flags/flags/li.svg' },
  { label: 'Lithuania', value: 'LT', flag: 'https://kapowaz.github.io/square-flags/flags/lt.svg' },
  { label: 'Luxembourg', value: 'LU', flag: 'https://kapowaz.github.io/square-flags/flags/lu.svg' },
  { label: 'Madagascar', value: 'MG', flag: 'https://kapowaz.github.io/square-flags/flags/mg.svg' },
  { label: 'Malawi', value: 'MW', flag: 'https://kapowaz.github.io/square-flags/flags/mw.svg' },
  { label: 'Malaysia', value: 'MY', flag: 'https://kapowaz.github.io/square-flags/flags/my.svg' },
  { label: 'Maldives', value: 'MV', flag: 'https://kapowaz.github.io/square-flags/flags/mv.svg' },
  { label: 'Mali', value: 'ML', flag: 'https://kapowaz.github.io/square-flags/flags/ml.svg' },
  { label: 'Malta', value: 'MT', flag: 'https://kapowaz.github.io/square-flags/flags/mt.svg' },
  { label: 'Marshall Islands', value: 'MH', flag: 'https://kapowaz.github.io/square-flags/flags/mh.svg' },
  { label: 'Mauritania', value: 'MR', flag: 'https://kapowaz.github.io/square-flags/flags/mr.svg' },
  { label: 'Mauritius', value: 'MU', flag: 'https://kapowaz.github.io/square-flags/flags/mu.svg' },
  { label: 'Mexico', value: 'MX', flag: 'https://kapowaz.github.io/square-flags/flags/mx.svg' },
  { label: 'Micronesia', value: 'FM', flag: 'https://kapowaz.github.io/square-flags/flags/fm.svg' },
  { label: 'Moldova', value: 'MD', flag: 'https://kapowaz.github.io/square-flags/flags/md.svg' },
  { label: 'Monaco', value: 'MC', flag: 'https://kapowaz.github.io/square-flags/flags/mc.svg' },
  { label: 'Mongolia', value: 'MN', flag: 'https://kapowaz.github.io/square-flags/flags/mn.svg' },
  { label: 'Montenegro', value: 'ME', flag: 'https://kapowaz.github.io/square-flags/flags/me.svg' },
  { label: 'Morocco', value: 'MA', flag: 'https://kapowaz.github.io/square-flags/flags/ma.svg' },
  { label: 'Mozambique', value: 'MZ', flag: 'https://kapowaz.github.io/square-flags/flags/mz.svg' },
  { label: 'Myanmar', value: 'MM', flag: 'https://kapowaz.github.io/square-flags/flags/mm.svg' },
  { label: 'Namibia', value: 'NA', flag: 'https://kapowaz.github.io/square-flags/flags/na.svg' },
  { label: 'Nauru', value: 'NR', flag: 'https://kapowaz.github.io/square-flags/flags/nr.svg' },
  { label: 'Nepal', value: 'NP', flag: 'https://kapowaz.github.io/square-flags/flags/np.svg' },
  { label: 'Netherlands', value: 'NL', flag: 'https://kapowaz.github.io/square-flags/flags/nl.svg' },
  { label: 'New Zealand', value: 'NZ', flag: 'https://kapowaz.github.io/square-flags/flags/nz.svg' },
  { label: 'Nicaragua', value: 'NI', flag: 'https://kapowaz.github.io/square-flags/flags/ni.svg' },
  { label: 'Niger', value: 'NE', flag: 'https://kapowaz.github.io/square-flags/flags/ne.svg' },
  { label: 'Nigeria', value: 'NG', flag: 'https://kapowaz.github.io/square-flags/flags/ng.svg' },
  { label: 'North Korea', value: 'KP', flag: 'https://kapowaz.github.io/square-flags/flags/kp.svg' },
  { label: 'North Macedonia', value: 'MK', flag: 'https://kapowaz.github.io/square-flags/flags/mk.svg' },
  { label: 'Norway', value: 'NO', flag: 'https://kapowaz.github.io/square-flags/flags/no.svg' },
  { label: 'Oman', value: 'OM', flag: 'https://kapowaz.github.io/square-flags/flags/om.svg' },
  { label: 'Pakistan', value: 'PK', flag: 'https://kapowaz.github.io/square-flags/flags/pk.svg' },
  { label: 'Palau', value: 'PW', flag: 'https://kapowaz.github.io/square-flags/flags/pw.svg' },
  { label: 'Palestine', value: 'PS', flag: 'https://kapowaz.github.io/square-flags/flags/ps.svg' },
  { label: 'Panama', value: 'PA', flag: 'https://kapowaz.github.io/square-flags/flags/pa.svg' },
  { label: 'Papua New Guinea', value: 'PG', flag: 'https://kapowaz.github.io/square-flags/flags/pg.svg' },
  { label: 'Paraguay', value: 'PY', flag: 'https://kapowaz.github.io/square-flags/flags/py.svg' },
  { label: 'Peru', value: 'PE', flag: 'https://kapowaz.github.io/square-flags/flags/pe.svg' },
  { label: 'Philippines', value: 'PH', flag: 'https://kapowaz.github.io/square-flags/flags/ph.svg' },
  { label: 'Poland', value: 'PL', flag: 'https://kapowaz.github.io/square-flags/flags/pl.svg' },
  { label: 'Portugal', value: 'PT', flag: 'https://kapowaz.github.io/square-flags/flags/pt.svg' },
  { label: 'Qatar', value: 'QA', flag: 'https://kapowaz.github.io/square-flags/flags/qa.svg' },
  { label: 'Romania', value: 'RO', flag: 'https://kapowaz.github.io/square-flags/flags/ro.svg' },
  { label: 'Russia', value: 'RU', flag: 'https://kapowaz.github.io/square-flags/flags/ru.svg' },
  { label: 'Rwanda', value: 'RW', flag: 'https://kapowaz.github.io/square-flags/flags/rw.svg' },
  { label: 'Saint Kitts and Nevis', value: 'KN', flag: 'https://kapowaz.github.io/square-flags/flags/kn.svg' },
  { label: 'Saint Lucia', value: 'LC', flag: 'https://kapowaz.github.io/square-flags/flags/lc.svg' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC', flag: 'https://kapowaz.github.io/square-flags/flags/vc.svg' },
  { label: 'Samoa', value: 'WS', flag: 'https://kapowaz.github.io/square-flags/flags/ws.svg' },
  { label: 'San Marino', value: 'SM', flag: 'https://kapowaz.github.io/square-flags/flags/sm.svg' },
  { label: 'Sao Tome and Principe', value: 'ST', flag: 'https://kapowaz.github.io/square-flags/flags/st.svg' },
  { label: 'Saudi Arabia', value: 'SA', flag: 'https://kapowaz.github.io/square-flags/flags/sa.svg' },
  { label: 'Senegal', value: 'SN', flag: 'https://kapowaz.github.io/square-flags/flags/sn.svg' },
  { label: 'Serbia', value: 'RS', flag: 'https://kapowaz.github.io/square-flags/flags/rs.svg' },
  { label: 'Seychelles', value: 'SC', flag: 'https://kapowaz.github.io/square-flags/flags/sc.svg' },
  { label: 'Sierra Leone', value: 'SL', flag: 'https://kapowaz.github.io/square-flags/flags/sl.svg' },
  { label: 'Singapore', value: 'SG', flag: 'https://kapowaz.github.io/square-flags/flags/sg.svg' },
  { label: 'Slovakia', value: 'SK', flag: 'https://kapowaz.github.io/square-flags/flags/sk.svg' },
  { label: 'Slovenia', value: 'SI', flag: 'https://kapowaz.github.io/square-flags/flags/si.svg' },
  { label: 'Solomon Islands', value: 'SB', flag: 'https://kapowaz.github.io/square-flags/flags/sb.svg' },
  { label: 'Somalia', value: 'SO', flag: 'https://kapowaz.github.io/square-flags/flags/so.svg' },
  { label: 'South Africa', value: 'ZA', flag: 'https://kapowaz.github.io/square-flags/flags/za.svg' },
  { label: 'South Korea', value: 'KR', flag: 'https://kapowaz.github.io/square-flags/flags/kr.svg' },
  { label: 'South Sudan', value: 'SS', flag: 'https://kapowaz.github.io/square-flags/flags/ss.svg' },
  { label: 'Spain', value: 'ES', flag: 'https://kapowaz.github.io/square-flags/flags/es.svg' },
  { label: 'Sri Lanka', value: 'LK', flag: 'https://kapowaz.github.io/square-flags/flags/lk.svg' },
  { label: 'Sudan', value: 'SD', flag: 'https://kapowaz.github.io/square-flags/flags/sd.svg' },
  { label: 'Suriname', value: 'SR', flag: 'https://kapowaz.github.io/square-flags/flags/sr.svg' },
  { label: 'Sweden', value: 'SE', flag: 'https://kapowaz.github.io/square-flags/flags/se.svg' },
  { label: 'Switzerland', value: 'CH', flag: 'https://kapowaz.github.io/square-flags/flags/ch.svg' },
  { label: 'Syria', value: 'SY', flag: 'https://kapowaz.github.io/square-flags/flags/sy.svg' },
  { label: 'Tajikistan', value: 'TJ', flag: 'https://kapowaz.github.io/square-flags/flags/tj.svg' },
  { label: 'Tanzania', value: 'TZ', flag: 'https://kapowaz.github.io/square-flags/flags/tz.svg' },
  { label: 'Thailand', value: 'TH', flag: 'https://kapowaz.github.io/square-flags/flags/th.svg' },
  { label: 'Timor-Leste', value: 'TL', flag: 'https://kapowaz.github.io/square-flags/flags/tl.svg' },
  { label: 'Togo', value: 'TG', flag: 'https://kapowaz.github.io/square-flags/flags/tg.svg' },
  { label: 'Tonga', value: 'TO', flag: 'https://kapowaz.github.io/square-flags/flags/to.svg' },
  { label: 'Trinidad and Tobago', value: 'TT', flag: 'https://kapowaz.github.io/square-flags/flags/tt.svg' },
  { label: 'Tunisia', value: 'TN', flag: 'https://kapowaz.github.io/square-flags/flags/tn.svg' },
  { label: 'Turkey', value: 'TR', flag: 'https://kapowaz.github.io/square-flags/flags/tr.svg' },
  { label: 'Turkmenistan', value: 'TM', flag: 'https://kapowaz.github.io/square-flags/flags/tm.svg' },
  { label: 'Tuvalu', value: 'TV', flag: 'https://kapowaz.github.io/square-flags/flags/tv.svg' },
  { label: 'Uganda', value: 'UG', flag: 'https://kapowaz.github.io/square-flags/flags/ug.svg' },
  { label: 'Ukraine', value: 'UA', flag: 'https://kapowaz.github.io/square-flags/flags/ua.svg' },
  { label: 'United Arab Emirates', value: 'AE', flag: 'https://kapowaz.github.io/square-flags/flags/ae.svg' },
  { label: 'United Kingdom', value: 'GB', flag: 'https://kapowaz.github.io/square-flags/flags/gb.svg' },
  { label: 'United States', value: 'US', flag: 'https://kapowaz.github.io/square-flags/flags/us.svg' },
  { label: 'Uruguay', value: 'UY', flag: 'https://kapowaz.github.io/square-flags/flags/uy.svg' },
  { label: 'Uzbekistan', value: 'UZ', flag: 'https://kapowaz.github.io/square-flags/flags/uz.svg' },
  { label: 'Vanuatu', value: 'VU', flag: 'https://kapowaz.github.io/square-flags/flags/vu.svg' },
  { label: 'Vatican City', value: 'VA', flag: 'https://kapowaz.github.io/square-flags/flags/va.svg' },
  { label: 'Venezuela', value: 'VE', flag: 'https://kapowaz.github.io/square-flags/flags/ve.svg' },
  { label: 'Vietnam', value: 'VN', flag: 'https://kapowaz.github.io/square-flags/flags/vn.svg' },
  { label: 'Yemen', value: 'YE', flag: 'https://kapowaz.github.io/square-flags/flags/ye.svg' },
  { label: 'Zambia', value: 'ZM', flag: 'https://kapowaz.github.io/square-flags/flags/zm.svg' },
  { label: 'Zimbabwe', value: 'ZW', flag: 'https://kapowaz.github.io/square-flags/flags/zw.svg' }
];





const App = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isFlagLoaded, setIsFlagLoaded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleScreenshot = async () => {
    const element = document.getElementById("image-container");
    setLoading(true);
  
    try {
      // Wait for the flag image to be fully loaded before taking the screenshot
      if (isFlagLoaded) {
        const imageContainer = document.getElementById("image-container");
        
        // Get the width and height of the image container
        const containerWidth = imageContainer.offsetWidth;
        const containerHeight = imageContainer.offsetHeight;
  
        // Use html2canvas to capture the screenshot with the correct dimensions
        const canvas = await html2canvas(element, {
          useCORS: true,
          width: containerWidth,  // Set the canvas width to the container width
          height: containerHeight, // Set the canvas height to the container height
          x: 0,  // You can specify the offset if needed, for example for margins
          y: 0,
        });
  
        // Convert canvas to image data URL
        const image = canvas.toDataURL("image/png");
  
        // Create the download link and trigger the download
        const link = document.createElement("a");
        link.href = image;
        link.download = "babyneiro_with_flag.png";
        link.click();
  
        // Show snackbar notification
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error taking screenshot:", error);
    }
    setLoading(false);
  };
  
  return (<div style={{ backgroundColor:"#C7724A"}}>
    <Container style={{ padding: "20px", textAlign: "center", maxWidth: "500px" ,
    }}>
      <Typography 
  variant="h4" 
  gutterBottom 
  style={{ fontFamily: 'BOLTZZ Sans', fontWeight: 400, color: 'white' }}

>
  Baby Neiro with your Country’s Flag
</Typography>

<Typography
  variant="subtitle1"
  color="textSecondary"
  gutterBottom
  sx={{
    fontFamily: 'Bodoni MT',
    fontWeight: 400,color: 'white'
  }}
>
  Search your country’s name, select it, and download Baby Neiro Logo with your country’s flag.
</Typography>

      <Select
        options={countries}
        onChange={(country) => {
          setSelectedCountry(country);
          setIsFlagLoaded(false); 
        }}
        placeholder="Select a country"
        isSearchable
        styles={{
          container: (base) => ({
            ...base,
            marginBottom: "20px",
            width: "100%",
          }),
        }}
      />

<Box
      id="image-container"
      style={{
        position: "relative",
        display: "inline-block",
        height: "auto",
      }}
    >
      <img
        src={STATIC_IMAGE}
        alt="Static Background"
        style={{
          width: "100%",
          height: "auto",
        }}
      />

      {selectedCountry && (
        <div>
          {/* Right avatar */}
          <Avatar
            src={selectedCountry.flag}
            alt={selectedCountry.label}
            onLoad={() => setIsFlagLoaded(true)}
            sx={{
              position: "absolute",
              top: "31.5%",
                right: "27.8%",
                 width: "19%",
                height:"auto"
              // "@media (min-width: 320px) and (max-width: 360px)": { // Extra small screens, e.g., iPhone SE
              //   top: "100px",
              //   right: "87px",
              //   width: 65,
              //   height: 65,
              // },
              // "@media (min-width: 375px) and (max-width: 380px)": { // Small mobile screens
              //   top: "107px",
              //   right: "94px",
              //   width: 63,
              //   height: 63,
              // },
              // "@media (min-width: 414px) and (max-width: 415px)": { // Medium screens, e.g., tablets in portrait
              //   top: "120px",
              //   right: "105px",
              //   width: 70,
              //   height: 70,
              // },
              // "@media (min-width: 390px) and (max-width: 400px)": { // Large tablets or small laptops
              //   top: "109px",
              //   right: "96px",
              //   width: 68,
              //   height: 68,
              // },
              // "@media (min-width: 430px) and (max-width: 431px)": { // Large tablets or small laptops
              //   top: "124px",
              //   right: "109px",
              //   width: 76,
              //   height: 76,
              // },
              // "@media (min-width: 412px) and (max-width: 413px)": { // Large tablets or small laptops
              //   top: "115px",
              //   right: "102px",
              //   width: 75,
              //   height: 75,
              // },
              // "@media (min-width: 500px) and (max-width: 1580px)": { // Large tablets or small laptops
              //   top: "146px",
              //   right: "128px",
              //   width: 87,
              //   height: 87,
              // },
             
              // "@media (min-width: 344px) and (max-width: 345px)": { // Large tablets or small laptops
              //   top: "140px",
              //   right: "100px",
              //   width: 85,
              //   height: 85,
              // },
            }}
          />

          {/* Left avatar */}
          <Avatar
            src={selectedCountry.flag}
            alt={selectedCountry.label}
            onLoad={() => setIsFlagLoaded(true)}
            sx={{
              position: "absolute",
              top: "30%",
                left: "23.8%",
                 width: "19%",
                height:"auto"
              // "@media(min-width: 320px) and (max-width: 360px)": { // Extra small screens, e.g., iPhone SE
              //   top: "95px",
              //   left: "74px",
              //   width: 65,
              //   height: 65,
              // },
              // "@media (min-width: 375px) and (max-width: 380px)": { // Small mobile screens
              //   top: "102px",
              //   left: "79px",
              //   width: 63,
              //   height: 63,
              // },
              // "@media (min-width: 414px) and (max-width: 415px)": { // Medium screens, e.g., tablets in portrait
              //   top: "114px",
              //   left: "89px",
              //   width: 70,
              //   height: 70,
              // },
              // "@media (min-width: 390px) and (max-width: 400px)": { // Large tablets or small laptops
              //   top: "106px",
              //   left: "84px",
              //   width: 65,
              //   height: 65,
              // },
              // "@media (min-width: 430px) and (max-width: 431px)": { // Large tablets or small laptops
              //   top: "117px",
              //   left: "93px",
              //   width: 75,
              //   height: 75,
              // },
              // "@media (min-width: 412px) and (max-width: 413px)": { // Large tablets or small laptops
              //   top: "110px",
              //   left: "85px",
              //   width: 75,
              //   height: 75,
              // },
              // "@media (min-width: 500px) and (max-width: 1580px)": { // Large tablets or small laptops
              //   top: "140px",
              //   left: "110px",
              //   width: 87,
              //   height: 87,
              // },
             
              // "@media (min-width: 344px) and (max-width: 345px)": { // Large tablets or small laptops
              //   top: "140px",
              //   right: "100px",
              //   width: 85,
              //   height: 85,
              // },
            }}
          />
        </div>
      )}
    </Box>

      <Button
        variant="contained"
        color="success"
        onClick={handleScreenshot}
        disabled={!selectedCountry || !isFlagLoaded || loading}
        style={{ width: "100%", padding: "10px 20px" }}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Save Image"}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%" }}>
          Image saved successfully!
        </Alert>
      </Snackbar>
    </Container></div>
  );
};

export default App;